<template>
  <div class="sharePromotion">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">分享推广</div>
      <router-link tag="div" to="/promotionRecord" class="rightBtn"
        >分享记录</router-link
      >
    </div>
    <div class="main">
      <div class="content">
        <!-- <svg-icon class="appName" iconClass="appName" /> -->
        <div class="mask">
          <div class="qrBorder">
            <div class="qrBox">
              <div ref="qrCodeUrl"></div>
            </div>
          </div>

          <div class="codeTitle">邀请码</div>
          <div class="shareCode">{{ userInfo.promotionCode }}</div>
          <div class="tip">
            提示*苹果手机请用相机扫码/安卓手机推荐UC浏览器扫码
          </div>
        </div>
      </div>
      <div class="optBox">
        <div class="btn" @click="save">保存图片</div>
        <div class="btnLine"></div>
        <div class="btn" @click="copyUrl">复制链接</div>
      </div>
      <div class="ruleTitle">规则说明</div>
      <div class="ruleItem">
        1、邀请<span>好友注册成功</span>即可获得<span>会员</span>。
      </div>
      <div class="ruleItem">
        2、邀请好友可获得会员<span>最高永久观影特权</span>。<br />
        例如：邀请好友张三，您即可获得<span>1天会员奖励,会员天数可累计叠加</span>！
      </div>
      <div class="ruleItem">
        3、邀请说明：点击【保存二维码】或【复制推广链接】。获取专属推广链接，推荐分享给其他人下载即可。
      </div>
      <div class="step"></div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { Toast } from "vant";
export default {
  data() {
    return {
      userInfo: "",
    };
  },
  created() {
    this.$store.dispatch("user/getUserInfo");
    this.userInfo = this.$store.getters.userInfo;
    this.generateCode(this.userInfo.promoteURL);
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 生成二维码
    generateCode(url) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 135,
          height: 135,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    // 复制链接
    copyUrl() {
      this.$copyText(this.userInfo.promoteURL).then(
        (e) => {
          Toast("复制成功");
        },
        (e) => {
          Toast("复制失败");
        }
      );
    },
    save() {
      Toast("请手动截图保存账号凭证");
    },
  },
};
</script>

<style lang="scss" scoped>
.sharePromotion {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px 0;
    // box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 43px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 60px;
      font-size: 14px;
      height: 17px;
      color: #999999;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px;
    box-sizing: border-box;
  }

  .content {
    height: 607px;
    background: url("../../../assets/png/promotionBg.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    // background-size: 100% 100%;
    // .appName {
    //     width: 54px;
    //     height: 27.7px;
    //     margin: 12px 0 0 19px;
    // }
    .mask {
      margin-top: 263px;
      height: 344px;
      width: 100%;
      border-radius: 0px 0px 12px 12px;
      background: linear-gradient(180deg, rgba(17, 21, 35, 0) 0%, #111523 100%);
      overflow: hidden;
    }
    .qrBorder {
      width: 174px;
      height: 174px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 53px auto 16px;
      background-image: url("../../../assets/png/qrBox.png");
      background-size: 100% 100%;
      // padding: 10px;
      // box-sizing: border-box;
      .qrBox {
        width: 154px;
        height: 154px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .codeTitle {
      font-size: 18px;
      text-align: center;
      color: #fff;
    }

    .shareCode {
      font-size: 20px;
      color: #b09fd9;
      text-align: center;
      margin: 6px 0 10px;
    }

    .officialUrl {
      // width: 242px;
      width: fit-content;
      height: 30px;
      border-radius: 30px;
      line-height: 30px;
      margin: 0 auto 18px;
      padding: 0 32px;
      box-sizing: border-box;
      text-align: center;
      color: #fff;
      background: rgba(255, 255, 255, 0.15);
      font-size: 13px;
    }

    .tip {
      color: #fff;
      font-size: 10px;
      text-align: center;
      font-weight: 400;
    }
  }
  .optBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto 22px;
    width: 333px;
    height: 40px;
    border-radius: 20px;
    background: #f0f0f3;
    box-shadow: -5px -5px 20px 0px #fff,
      5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    .btnLine {
      width: 1px;
      height: 100%;
      background: #c3c9cd70;
    }
    .btn {
      width: 166px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #b09fd9;
      font-family: "Dream Han Sans TC";
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px;
      // width: 120px;
      // height: 40px;
      // line-height: 40px;
      // font-size: 14px;
      // border-radius: 40px;
      // text-align: center;
      // color: #fff;
      // background: #94d6da;
      // margin-right: 18px;
    }
    .btn:last-child {
      margin-right: 0;
    }
  }
  .ruleTitle {
    margin-bottom: 12px;
    font-weight: 900;
    font-family: "Dream Han Sans TC";
  }
  .ruleItem {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #fc4162;
    }
  }
  .step {
    margin-top: 30px;
    width: 343px;
    height: 460px;
    background-image: url("../../../assets/png/stepImg.png");
    background-size: 100% 100%;
  }

  .tipItem {
    height: 49px;
    padding: 0 12px;
    font-size: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grey {
    background: #e6e6e6;
  }

  .mb50 {
    margin-bottom: 50px;
  }
}
</style>
